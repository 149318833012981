<template>
  <v-text-field
    v-model="formation"
    dense
    label="Nom de la formation"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formation: '',
    }
  },
  watch: {
    student(newVal) {
      if (newVal && newVal.formation) {
        this.formation = newVal.formation
      }
    },
    formation(value) {
      if (value) {
        this.pushVal(value)
      }
    },
  },
  created() {
    if (this.student.formation) {
      this.formation = this.student.formation
    }
    this.pushVal(this.formation)
  },
  methods: {
    pushVal(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
