<template>
  <div>
    <v-card>
      <v-card-title>
        Paramètres
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="selectedStudent"
            :filter="customFilter"
            :clearable="true"
            :items="students"
            no-data-text="Pas de stagiaire correspondant"
            outlined
            dense
            label="Stagiaire"
          >
            <template
              slot="item"
              slot-scope="data"
            >
              {{ data.item.prenom }} {{ data.item.nom }} - {{ data.item.formation ? data.item.formation : '' }}
            </template>
            <template
              slot="selection"
              slot-scope="data"
            >
              {{ data.item.prenom }} {{ data.item.nom }} - {{ data.item.formation ? data.item.formation : '' }}
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-text v-if="!loading">
        <TextesVariablesDynamicFiller
          :content="texts.find(x => x.id === selectedText).content"
          :student="selectedStudent"
          @variableReplaced="variableReplaced"
          @variablesSetup="variablesSetup"
        ></TextesVariablesDynamicFiller>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-if="selectedStudent"
          v-model="selectedStudent.email"
          :prepend-icon="icons.mdiAt"
          readonly
          dense
          :append-icon="icons.mdiClipboard"
          @click:append="copyClip(selectedStudent.email)"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-card
      v-if="!loading"
      class="mt-5"
    >
      <v-card-title>
        Contenu
        <v-btn
          class="mx-5"
          color="primary"
          @click="copyClip(textArea)"
        >
          <v-icon>
            {{ icons.mdiClipboard }}
          </v-icon>
        </v-btn>
        <EmailModalVue
          :subject="createSubject(selectedText)"
          :destinataire="selectedStudent.id ? selectedStudent.email : ''"
          :to="selectedStudent.id ? selectedStudent.id : 0"
          :content="textArea"
        ></EmailModalVue>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedText"
          :items="texts"
          :item-text="item => item.title"
          :item-value="item=> item.id"
          @change="texteRendered"
        ></v-select>
      </v-card-text>
      <v-card-text>
        <v-textarea
          v-model="textArea"
          outlined
          :value="textArea"
          rows="15"
          row-height="20"
        >
        </v-textarea>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      class="mt-5"
    >
      <v-skeleton-loader
        type="article, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import { mdiClipboard, mdiAt, mdiCalendar } from '@mdi/js'
import EmailModalVue from '@/components/EmailModal.vue'
import TextesVariablesDynamicFiller from './TextesVariablesDynamicFiller.vue'

export default {
  components: {
    TextesVariablesDynamicFiller,
    EmailModalVue,
  },
  data() {
    return {
      selectedStudent: {
        id: null,
      },
      selectedText: null,
      icons: {
        mdiClipboard,
        mdiAt,
        mdiCalendar,
      },
      loading: true,
      students: [],
      texts: [],
      certification: [{ nom: '' }],
      replacedVars: {
        variables: [],
        updatedVariables: [],
      },
      textArea: '',
    }
  },
  computed: {
    dateAppelNice() {
      if ((this.selectedStudent) && (this.selectedStudent.contacts.length > 0)) {
        const lastContact = this.selectedStudent.contacts
        lastContact.sort((a, b) => new Date(b.contactDate) - new Date(a.contactDate))

        return this.$date(new Date(lastContact[0].contactDate), 'dd/MM/yyyy')
      }

      return this.$date(new Date(this.dateAppel), 'dd/MM/yyyy')
    },
  },
  watch: {
    replacedVars(val) {
      if (val) {
        this.texteRendered()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    updateDateTime(e) {
      this.rdv.date = this.$date(e, 'dd/MM/yyyy')
      this.rdv.heure = this.$date(e, 'HH:mm')
    },
    texteRendered() {
      if (this.texts.length === 0) {
        this.textArea = 'En attentes des textes...'

        return this.textArea
      }
      if (this.replacedVars.variables.length === 0) {
        this.textArea = 'En attentes des variables...'

        return this.textArea
      }
      const { texts } = this
      let foundText = texts.find(x => x.id === this.selectedText).content
      for (let i = 0; i < this.replacedVars.variables.length; i += 1) {
        if ((this.replacedVars.variables[i] !== this.replacedVars.updatedVariables[i])
        && (this.replacedVars.updatedVariables[i] !== undefined)
        && (this.replacedVars.updatedVariables[i].length > 0)) {
          while (foundText.indexOf(this.replacedVars.variables[i]) > -1) {
            foundText = foundText.replace(this.replacedVars.variables[i], this.replacedVars.updatedVariables[i])
          }
        }
      }
      this.textArea = foundText

      return this.textArea
    },
    variableReplaced(payload) {
      this.replacedVars.updatedVariables = payload.updatedVariables
      this.texteRendered()
    },
    variablesSetup(payload) {
      this.replacedVars.variables = payload.variables
      this.texteRendered()
    },
    fetchData() {
      Promise.all([this.fetchStudentLight(), this.fetchTexts()])
        .then(values => {
          [this.students, this.texts] = values
          this.selectedText = this.texts[0].id
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchStudentLight() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/students/light`)

      return res.data
    },
    async fetchTexts() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/textes?categorie=type`)

      return res.data
    },
    customFilter(item, queryText) {
      const textOne = item.nom.toLowerCase()
      const textTwo = item.prenom.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1
          || textTwo.indexOf(searchText) > -1
    },
    createSubject(id) {
      const { emailSubject } = this.texts.find(x => x.id === id)
      const companyName = 'La Formation Pro'
      const textTitle = this.texts.find(x => x.id === id).title
      let studentName = ''
      if (this.selectedStudent) {
        studentName = `${this.selectedStudent.nom} ${this.selectedStudent.prenom}`
      }
      if (emailSubject && emailSubject.length > 0) {
        return `${companyName} : ${emailSubject} - ${studentName}`
      }

      return `${companyName} : ${textTitle} - ${studentName}`
    },
    copyClip(e) {
      navigator.clipboard.writeText(e)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'info',
            text: 'Copié dans le presse-papier',
            value: true,
          })
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la copie',
            value: true,
          })
          console.log(err)
        })
    },
  },
}
</script>

<style>

</style>
