<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateNice"
        :prepend-icon="icons.mdiCalendar"
        readonly
        dense
        v-bind="attrs"
        label="Date"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      icons: {
        mdiCalendar,
      },
    }
  },
  computed: {
    dateNice() {
      return this.$date(new Date(this.date), 'dd/MM/yyyy')
    },
  },
  watch: {
    date(value) {
      console.log(value)
      if (value) {
        this.pushVar(this.dateNice)
      }
    },
  },
  created() {
    this.pushVar(this.dateNice)
  },
  methods: {
    pushVar(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
