<template>
  <v-card-text>
    <v-row class="d-flex justify-space-between">
      <div
        v-for="(variable, index) in variables"
        :key="index"
      >
        <v-col>
          <component
            :is="matchingComponent(variable)"
            :variable="variable"
            :student="student"
            :index="index"
            :prenom="prenom"
            :nom="nom"
            @variableUpdated="variableUpdated"
          ></component>
        </v-col>
        <div v-if="index%3 === 0 && index !==0">
        </div>
      </div>
    </v-row>
  </v-card-text>
</template>

<script>
import TextesVariablesDynamicComponentGender from './TextesVariablesDynamicComponentGender.vue'
import TextesVariablesDynamicComponentNom from './TextesVariablesDynamicComponentNom.vue'
import TextesVariablesDynamicComponentPrenom from './TextesVariablesDynamicComponentPrenom.vue'
import TextesVariablesDynamicComponentDate from './TextesVariablesDynamicComponentDate.vue'
import TextesVariablesDynamicComponentFormation from './TextesVariablesDynamicComponentFormation.vue'
import TextesVariablesDynamicComponentDernierrdv from './TextesVariablesDynamicComponentDernierrdv.vue'
import TextesVariablesDynamicComponentProchainrappel from './TextesVariablesDynamicComponentProchainrappel.vue'
import TextesVariablesDynamicComponentDebutformation from './TextesVariablesDynamicComponentDebutformation.vue'
import TextesVariablesDynamicComponentEmail from './TextesVariablesDynamicComponentEmail.vue'
import TextesVariablesDynamicComponentPassword from './TextesVariablesDynamicComponentPassword.vue'

export default {
  components: {
    TextesVariablesDynamicComponentGender,
    TextesVariablesDynamicComponentNom,
    TextesVariablesDynamicComponentPrenom,
    TextesVariablesDynamicComponentDate,
    TextesVariablesDynamicComponentFormation,
    TextesVariablesDynamicComponentDernierrdv,
    TextesVariablesDynamicComponentProchainrappel,
    TextesVariablesDynamicComponentDebutformation,
    TextesVariablesDynamicComponentEmail,
    TextesVariablesDynamicComponentPassword,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    student: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      updatedVariables: [],
      variables: [],
      variablesFull: [],
      nom: '',
      prenom: '',
    }
  },
  watch: {
    content(val) {
      this.variablesList(val)
    },

  },
  mounted() {
    this.variablesList(this.content)
  },
  methods: {
    variablesList(content) {
      if (content) {
        const regExp = RegExp(/\[\[(.*?)\]\]/g)
        this.variables = []
        this.variablesFull = []

        content.match(regExp).forEach(match => {
          this.variablesFull.push(match)
          match = match.replace('[[', '')
          match = match.replace(']]', '')
          this.variables.push(match)
        })
        this.$emit('variablesSetup', { variables: this.variablesFull })

        return { variables: this.variables, variablesFull: this.variablesFull }
      }

      return []
    },
    matchingComponent(variable) {
      let varname = variable.toLowerCase()
      varname = varname.charAt(0).toUpperCase() + varname.slice(1)

      return `TextesVariablesDynamicComponent${varname}`
    },
    variableUpdated(payload) {
      const { updatedVariables } = this
      updatedVariables[payload.index] = payload.data
      const nomIndex = this.variables.indexOf('NOM')
      const prenomIndex = this.variables.indexOf('PRENOM')
      this.prenom = this.updatedVariables[nomIndex]
      this.nom = this.updatedVariables[prenomIndex]
      this.$emit('variableReplaced', { updatedVariables })
      console.log(this.updatedVariables)
    },
  },

}
</script>

<style>

</style>
